export default [
  {
    id: 125,
    firstName: `Kevin`,
    lastName: `Love`,
    position: `PF`
  },
  {
    id: 88,
    firstName: `Fred`,
    lastName: `Beckens`,
    position: `QB`,
    team: `MIS`
  },
  {
    id: 89,
    firstName: `Bard`,
    lastName: `Fredricks`,
    position: `RB`,
    team: `MIS`
  },
  {
    id: 90,
    firstName: `Hal`,
    lastName: `Yugen`,
    position: `LB`,
    team: `ALB`
  },
  {
    id: 91,
    firstName: `Tan`,
    lastName: `Hidens`,
    position: `SG`,
    team: `ALB`
  },
  {
    id: 110,
    firstName: `Sal`,
    lastName: `Romoni`,
    position: `QB`,
    team: `ARK`
  },
  {
    id: 111,
    firstName: `Tuck`,
    lastName: `Enroll`,
    position: `SG`,
    team: `ARK`
  },
  {
    id: 112,
    firstName: `Same`,
    lastName: `Bunt`,
    position: `LB`,
    team: `LOU`
  },
  {
    id: 126,
    firstName: `LeBran`,
    lastName: `James`,
    position: `SF`,
    team: `TEX`
  },
  {
    id: 127,
    firstName: `Jae`,
    lastName: `Crowder`,
    position: `PF`,
    team: `TEX`
  },
  {
    id: 128,
    firstName: `Jeff`,
    lastName: `Green`,
    position: `PF`,
    team: `OKL`
  },
  {
    id: 129,
    firstName: `Dwayne`,
    lastName: `Wade`,
    position: `SG`,
    team: `OKL`
  },
  {
    id: 122,
    firstName: `John`,
    lastName: `Tilbrit`,
    position: `RB`,
    team: `CAL`
  },
  {
    id: 123,
    firstName: `J.R.`,
    lastName: `Smith`,
    position: `QB`,
    team: `CAL`
  },
  {
    id: 124,
    firstName: `Iman`,
    lastName: `Shumpert`,
    position: `SG`,
    team: `COL`
  }
];
